import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Header from './Header/Header';
import Home from './Home/Home';
import LearnMore from './LearnMore/LearnMore';
import Support from './Support/Support';
import PageNotFound from './PageNotFound/PageNotFound';
import Footer from './Footer/Footer';
import AppHome from './App/Home/AppHome';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
	  	<Header />
		<div className='container'>
			<Routes>
				<Route path='/' element={<Home/>} />
				<Route path='/learn-more' element={<LearnMore/>} />
				<Route path='/synths' element={<Support/>} />
				<Route path='/device-support' element={<Support/>} />
				<Route path='/request-support' element={<Support/>} />
				<Route path='/app' element={<AppHome/>} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</div>
		<Footer />
	  </BrowserRouter>
    </div>
  );
}

export default App;
