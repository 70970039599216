import React, { useEffect, useState } from 'react';
import './MidiDeviceList.css';

const MidiDeviceList = () => {
  const [midiDevices, setMidiDevices] = useState([]);
  const [connectedDevice, setConnectedDevice] = useState(null);
  const [lastMessage, setLastMessage] = useState('');
  const [hasMIDIAccessPermission, setHasMIDIAccessPermission] = useState(false);

  useEffect(() => {
    navigator.permissions.query({ name: "midi", sysex: true }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
            _requestMIDIAccess();
        }
        // Permission was denied by user prompt or permission policy
      });
    
  }, []);

  const _requestMIDIAccess = () =>
  {
    navigator.requestMIDIAccess().then(
        (midiAccess) => {
            setHasMIDIAccessPermission(true);
            const devices = [];
            const inputs = midiAccess.inputs.values();
            for (let input = inputs.next(); input && !input.done; input = inputs.next()) {
                devices.push(input.value);
            }
            setMidiDevices(devices);
  
            midiAccess.onstatechange = () => {
                const updatedDevices = [];
                const updatedInputs = midiAccess.inputs.values();
                for (let input = updatedInputs.next(); input && !input.done; input = updatedInputs.next()) {
                updatedDevices.push(input.value);
                }
                setMidiDevices(updatedDevices);
            };
        },
        (error) => {
          console.log('Failed to access MIDI devices: ' + error);
        }
      );
  }

  const connectToDevice = (device) => {
    console.log(`connecting to: ${device.name}`)
    if (connectedDevice) {
      connectedDevice.onmidimessage = null;
      connectedDevice.close();
    }

    device.onmidimessage = (event) => {
      // Handle MIDI messages received from the device
      console.log('Received MIDI message:', event.data);
      setLastMessage(event.data);
    };

    setConnectedDevice(device);
  };

  return (
    <div className='pup-midi-device-list-container'>
      <h1>MIDI Devices</h1>
      {!hasMIDIAccessPermission && <div>No MIDI Access Permission</div>}
      {midiDevices.length > 0 ? (
        <ul>
          {midiDevices.map((device, index) => (
            <li key={index} onClick={() => connectToDevice(device)} className={device.name === connectedDevice?.name ? 'connected' : ''}>
              {device.name}
            </li>
          ))}
        </ul>
      ) : (
        <p>No MIDI devices found.</p>
      )}
      <div>
        {lastMessage}
      </div>
    </div>
  );
};

export default MidiDeviceList;
