import './Header.css';
import { useHref, useLocation, useNavigate } from "react-router-dom";
import logo from '../assets/Logo44x44.png';

function Header() {

	const navigate = useNavigate();
	const href = useLocation();

	
	return (
		<div className="headerContainer">
			<span className='homeIcon' onClick={() => {navigate('/')}} >
				<img src={logo} className='logoImg' />
				patchup
			</span>
			<a onClick={() => {navigate('/learn-more')}}  className={href.pathname === '/learn-more' ? 'active' : ''}>
				learn more
			</a>
		</div>
	);
}

export default Header;
