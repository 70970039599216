import './Footer.css';
import { useNavigate } from "react-router-dom";

function Footer() {

	const navigate = useNavigate();
	
	return (
		<div className="footerContainer">
			<div className='footerItem'>
				<span style={{'marginBottom': '10px'}}>Your feedback is appreciated. Contact us at <a href="mailto:hello@patchup.app">hello@patchup.app</a>. </span>
				
				<span><a href='https://www.microsoft.com/store/apps/9NGFHJ4DGT72' target='_blank' style={{}}>version 1.1.4</a> released to the Microsoft store on 10/24/2022</span>
			</div>

			<div className='footerItem'>
				<span></span>
			</div>
		</div>
	);
}

export default Footer;
