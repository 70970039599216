import './LearnMore.css';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


const initialOpenOptions = {
	'connecting': false,
	'midiDevices': false,
	'patches': false,
	'sysexSandbox': false,
	'roadmap': false,
	'deviceSpecificFeatures': false
}

function LearnMore() {

	const connectingRef = useRef();
	const midiDevicesRef = useRef();
	const patchesRef = useRef();
	const sysexSandboxRef = useRef();
	const roadmapRef = useRef();
	const deviceSpecificFeaturesRef = useRef();

	const [openOptions, setOpenOptions] = useState(initialOpenOptions);
	const navigate = useNavigate();
	const href = useLocation();

	useEffect(() => {
		if (href.hash){
			const strippedHash = href.hash.replace('#', '');
			setOpenOptions({...openOptions, [strippedHash]: true})
			if (strippedHash === 'deviceSpecificFeatures'){
				setTimeout(() =>{
					scrollToDocItem(deviceSpecificFeaturesRef.current);
				}, 50);
			}
		}
	}, [href]);

	function scrollToDocItem(element){
		element.scrollIntoView();
		if (window.scrollY){
			window.scrollTo({
				top: window.scrollY - 80,
  				left: 0,
				behavior: 'smooth'
			})
		}
	}


	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="docsContainer">
			<div className='docsContent'>
				<div className='docsInnerContent'>
					
					{/* CONNECTING TO A SYNTH */}
					<div className={'docItem ' + (openOptions['connecting'] ? ' opened' : '')}>
						<div className={'labelContainer' + (openOptions['connecting'] ? ' opened' : '')} onClick={() => setOpenOptions({...openOptions, 'connecting': !openOptions['connecting']})}>
							{
								openOptions['connecting']
								?
								<img src={require('../assets/minimize.png')} className='minimizeIcon' />
								:
								<img src={require('../assets/add.png')} className='addIcon'/>
							}
							<h3 style={{'color': openOptions['connecting'] ? 'var(--accent)' : 'var(--foreground)'}} ref={connectingRef}>Connecting To A Synth</h3>
						</div>
						{
							openOptions['connecting'] &&
							<div className='content'>
								<p>Plug your synth into your computer via USB or MIDI. Patchup will match MIDI inputs to MIDI outputs and automatically connect to them.</p>
								<p>Each MIDI device with a matching MIDI input & output will appear in the MIDI device list.</p>
								<br/>
								<p><span>Your synth settings should match the following as best as possible:</span>
									<ul>
										<div className='row connectingLi'>
											<div className='connectingLiLabel'><li>MIDI Channel:  </li> </div>
											<div className='connectingLiValue'><em className='msgItalics'>All</em></div>
										</div>
										<div className='row connectingLi'>
											<div className='connectingLiLabel'><li>MIDI Control: </li> </div>
											<div className='connectingLiValue'><em className='msgItalics'> On</em></div>
										</div>
										<div className='row connectingLi'>
											<div className='connectingLiLabel'><li>MIDI Program Enable: </li> </div>
											<div className='connectingLiValue'><em className='msgItalics'>On</em></div>
										</div>
										<div className='row connectingLi'>
											<div className='connectingLiLabel'><li>MIDI SysEx Cable: </li> </div>
											<div className='connectingLiValue'><em className='msgItalics'>Must match your connection type</em></div>
										</div>
										<div className='row connectingLi'>
											<div className='connectingLiLabel'><li>MIDI Out Select: </li> </div>
											<div className='connectingLiValue'><em className='msgItalics'>Must match your connection type</em></div>
										</div>
										<div className='row connectingLi'>
											<div className='connectingLiLabel'><li>MIDI Program Send: </li> </div>
											<div className='connectingLiValue'><em className='msgItalics'>On</em></div>
										</div>
									</ul>
								</p>
							</div>
						}
					</div>


					{/* MIDI DEVICE LIST */}
					<div className={'docItem ' + (openOptions['midiDevices'] ? ' opened' : '')}>
						<div className={'labelContainer' + (openOptions['midiDevices'] ? ' opened' : '')} onClick={() => setOpenOptions({...openOptions, 'midiDevices': !openOptions['midiDevices']})}>
							{
								openOptions['midiDevices']
								?
								<img src={require('../assets/minimize.png')} className='minimizeIcon' />
								:
								<img src={require('../assets/add.png')} className='addIcon' />
							}
							<h3 style={{'color': openOptions['midiDevices'] ? 'var(--accent)' : 'var(--foreground)'}} ref={midiDevicesRef}>MIDI Devices List</h3>
						</div>
						{
							openOptions['midiDevices'] &&
							<div className='content'>
								<p>The MIDI device list will show all MIDI devices connected to your computer. These are usually synthesizers.</p>
								<p>MIDI inputs are automatically matched to MIDI outputs. Once paired, patchup will try to connect to each port. If successful, it will send a device inquiry request. Regardless of connection success or device inquiry response, the MIDI device is added to the MIDI device list.</p>
								<p>A MIDI device will appear when its connected. It will disappear only when it has no connection <strong>and</strong> zero patches.</p>
								<br/>
								<h4>MIDI device connection statuses:</h4>
									<div className='connectionContainer'>
										<div className='connectionKey'><em className='msgItalics'>Connected</em></div>
										<div className='connectionDesc'><p>Your synth is plugged into your computer. Patchup has successfully connected to the MIDI input & output ports of the synth.</p> </div>
									</div>

									<div className='connectionContainer'>
										<div className='connectionKey'><em className='msgItalics'>Disconnected</em></div>
										<div className='connectionDesc'><p>Your synth is plugged into your computer. Patchup does not have an active MIDI connection to it, meaning it is available to be used by another application, such as a DAW.</p> </div>
									</div>

									<div className='connectionContainer'>
										<div className='connectionKey'><em className='msgItalics'>Unavailable</em></div>
										<div className='connectionDesc'><p>Your synth is plugged into your computer. Patchup is unable to receive a response from a device inquiry request. It's possible the synth is being used by another application, such as a DAW</p></div>
									</div>

									<div className='connectionContainer last'>
										<div className='connectionKey'><em className='msgItalics'>None</em></div>
										<div className='connectionDesc'><p>The synth is not plugged into your computer.</p> </div>
									</div>
							</div>
						}
					</div>


					{/* PATCHES */}
					<div className={'docItem ' + (openOptions['patches'] ? ' opened' : '')}>
						<div className={'labelContainer' + (openOptions['patches'] ? ' opened' : '')} onClick={() => setOpenOptions({...openOptions, 'patches': !openOptions['patches']})}>
							{
								openOptions['patches']
								?
								<img src={require('../assets/minimize.png')} className='minimizeIcon' />
								:
								<img src={require('../assets/add.png')} className='addIcon' />
							}
							<h3 style={{'color': openOptions['patches'] ? 'var(--accent)' : 'var(--foreground)'}} ref={patchesRef}>Patches</h3>
						</div>
						{
							openOptions['patches'] &&
							<div className='content'>
								<p>A patch is a collection of parameter settings for oscillators, envelopes, samples, filters, and more, which make up a sound on a synthesizer. Typically, patches are sent and received as SysEx messages.</p>
								<p>In patchup, a patch is saved with additional information such as a name, a note, the date saved, and the size of the SysEx message.</p>
								<br/>
								<div className='connectionKey'><em className='msgItalics'>Saving a patch</em></div>
								<ul>
									<li>Select a synth from the MIDI device list</li>
									<li>Select a folder</li>
									<li>Choose a method to save a patch. For most synths this will be <em className='msgItalics'>Manual</em>.</li>
									<li >Press<em className='msgItalics marginLeft10'>Save Patch</em></li>
									<li>Enter the SysEx message & press <em className='msgItalics'>save</em>. Name and notes are optional.</li>
								</ul>
								<p>Please see Device-Specific Features below if you are not saving a patch using the <em className='msgItalics'>Manual</em> method.</p>
								<br/>
								<div className='connectionKey'><em className='msgItalics'>Editing a patch</em></div>
								<p className='indented' >Select the patch you wish to edit and click <em className='msgItalics'>edit</em> from the patch details window. </p>
								<p>If the window is collapsed, expand it by clicking the<img src={require('../assets/chevron-up.png')} className='chevronUpImg' />in the bottom-right of the patches list.</p>
								<p >The name, note, and sysex message fields are editable.</p>
								<br/>
								<div className='connectionKey'><em className='msgItalics'>Sending a patch</em></div>
								<p className='indented'>There are three ways to send a patch:</p>
								<ul>
									<li>Double-click the patch</li>
									<li>Click <em className='msgItalics'>load</em> in the patch row</li>
									<li>Press the <em className='msgItalics'>enter</em> key when the patch is selected</li>
								</ul>
								<p >When a patch is sent, its background color will briefly flash.</p>
								<br/>
								<div className='connectionKey'><em className='msgItalics'>Receiving a patch</em></div>
								<p className='indented'>When saving a patch, enable <em className='msgItalics'>replace SysEx message bytes with incoming SysEx message</em> to use received SysEx messages.</p>
								<p>You can also copy incoming patches on the SysEx sandbox screen.</p>
							</div>
						}
					</div>


					{/* SYSEX SANDBOX */}
					<div className={'docItem ' + (openOptions['sysexSandbox'] ? ' opened' : '')}>
						<div className={'labelContainer' + (openOptions['sysexSandbox'] ? ' opened' : '')} onClick={() => setOpenOptions({...openOptions, 'sysexSandbox': !openOptions['sysexSandbox']})}>
							{
								openOptions['sysexSandbox']
								?
								<img src={require('../assets/minimize.png')} className='minimizeIcon' />
								:
								<img src={require('../assets/add.png')} className='addIcon' />
							}
							<h3 style={{'color': openOptions['sysexSandbox'] ? 'var(--accent)' : 'var(--foreground)'}} ref={sysexSandboxRef}>SysEx Sandbox</h3>
						</div>
						{
							openOptions['sysexSandbox'] &&
							<div className='content'>
								<p>SysEx Sandbox can also be used to send and receive SysEx messages to any connected MIDI device.</p>
								<p>The latest incoming message from the selected synth will be displayed automatically.</p>
								<p>Sent and received messages are not saved.</p>
								<p>SysEx messages can be displayed in hex, binary, or decimal.</p>
							</div>
						}
					</div>


					{/* ROADMAP */}
					<div className={'docItem ' + (openOptions['roadmap'] ? ' opened' : '')}>
						<div className={'labelContainer' + (openOptions['roadmap'] ? ' opened' : '')} onClick={() => setOpenOptions({...openOptions, 'roadmap': !openOptions['roadmap']})}>
							{
								openOptions['roadmap']
								?
								<img src={require('../assets/minimize.png')} className='minimizeIcon' />
								:
								<img src={require('../assets/add.png')} className='addIcon' />
							}
							<h3 style={{'color': openOptions['roadmap'] ? 'var(--accent)' : 'var(--foreground)'}} ref={roadmapRef}>Roadmap</h3>
						</div>
						{
							openOptions['roadmap'] &&
							<div className='content'>
								<p>Patchup is a passion project, and all its development has been done for fun.</p>
								<br/>
								<p>In no particular order, here's a list of things that I'd like to have in patchup:</p>
								<ul>
									<li>Import &amp; Export Patches</li>
									<li>Incoming SysEx message history (view the last 100 messages recieved from your synth)</li>
									<li>Nested folders</li>
									<li>Set keyboard shortcuts for application actions. Because keyboard {'>'} mouse.</li>
									<li>MacOS, iOS, Android, and browser support</li>
									<li>User accounts to sync between devices</li>
									<li>SysEx Controller - send multiple SysEx messages with a single press</li>
									<li>Drag &amp; drop patches &amp; folders to customize their sort order</li>
								</ul>
							</div>
						}
					</div>


					{/* DEVICE-SPECIFIC FEATURES */}
					<div className={'docItem ' + (openOptions['deviceSpecificFeatures'] ? ' opened' : '')}>
						<div className={'labelContainer' + (openOptions['deviceSpecificFeatures'] ? ' opened' : '')} onClick={() => setOpenOptions({...openOptions, 'deviceSpecificFeatures': !openOptions['deviceSpecificFeatures']})}>
							{
								openOptions['deviceSpecificFeatures']
								?
								<img src={require('../assets/minimize.png')} className='minimizeIcon' />
								:
								<img src={require('../assets/add.png')} className='addIcon' />
							}
							<h3 style={{'color': openOptions['deviceSpecificFeatures'] ? 'var(--accent)' : 'var(--foreground)'}} ref={deviceSpecificFeaturesRef}>Device-Specific Features</h3>
						</div>
						{
							openOptions['deviceSpecificFeatures'] &&
							<div className='content'>
								<p>Device-specific functionality is available to certain synthesizers.</p>
								<p>Examples of functionality include one-click to save the current patch and renaming a patch.</p>
								<p>If you would like to add special functionality into your synth, <a href="/request-support">click here</a></p>
								<br/>
								<br/>
								{/* REQUEST PATCH DUMP */}
								<em className='msgItalics'>Request Patch Dump</em>
								<p>Sends a request for the current patch. The response automatically saves a new patch into the selected folder.</p>
								<p className='bold'>Supports: Prophet X & Rev 2 by Sequential Circuits</p>
								<br/>
								<br/>

								{/* REQUEST PROGRAM DUMP */}
								<em className='msgItalics'>Request Program Dump</em>
								<p>Sends a request for the patch saved to a specified location. The response automatically saves a new patch into the selected folder.</p>
								<p className='bold'>Supports: Prophet X & Rev 2 by Sequential Circuits</p>
								<br/>
								<br/>

								{/* RENAME PATCH */}
								<em className='msgItalics'>Rename A Patch</em>
								<p>Renaming a patch in patchup will also update the patch's SysEx message with the new name.</p>
								<p>Subsequent loads of that patch will display the new name in the synthesizer.</p>
								<p className='bold'>Supports: Prophet X & Rev 2 by Sequential Circuits</p>
								<br/>
								<br/>
							</div>
						}						
					</div>

				</div>
			</div>
		</div>
	  );
}

export default LearnMore;
