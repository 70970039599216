import './Home.css';
import React from 'react';
import transparentKeyboard from '../assets/Keyboard_BackgroundTransparent.png';
import HomeScreen from '../assets/HomeScreen.png';
import PatchesScreen from '../assets/PatchesScreen.png';
import SysExSandboxScreen from '../assets/SysExSandbox.png';
import EditPatchScreen from '../assets/EditPatch.png';
import MicrosoftStore from '../assets/Microsoft_Store.png';
import ArrowLeft from '../assets/left-arrow.svg';
import ArrowRight from '../assets/right-arrow.svg';
import { useEffect, useRef, useState } from 'react';

<script type="module" src="https://getbadgecdn.azureedge.net/ms-store-badge.bundled.js"></script>


const sysexMessageVerbs = ['Send', 'Write', 'Edit', 'Receive', 'Save', 'Share', 'Organize', 'Search', 'Tag'];
const slideImages = [
	{
		url: HomeScreen, 
		altText: 'home screen',
		title: 'MIDI Devices List',
		caption: 'This is your starting point. Any connected synth will appear here'
	},
	{
		url: PatchesScreen, 
		altText: 'patches screen',
		title: 'Folders and Patches',
		caption: 'A glimpse into the folders and patches for my Prophet X'
	},
	{
		url: EditPatchScreen, 
		altText: 'editing a patch',
		title: 'Editing a Patch',
		caption: 'You can change the name, notes, and sysex message'
	},
	{
		url: SysExSandboxScreen,
		altText: 'SysEx Sandbox screen',
		title: 'SysEx Sandbox',
		caption: 'A dedicated area to send and receive SysEx messages. Below is a device inquiry message and response.'
	}
];


function Home() {
	
	const currentSysexMessageVerb = useRef(0);
	const [sysexMessageVerb, setSysexMessageVerb] = useState(sysexMessageVerbs[currentSysexMessageVerb.current]);
	const sysexMessageInterval = useRef(undefined);
	const [currentSlide, setCurrentSlide] = useState(0);


	useEffect(() => {
		window.scrollTo(0, 0);
		sysexMessageInterval.current = setInterval(() => {
			currentSysexMessageVerb.current = (currentSysexMessageVerb.current + 1) % sysexMessageVerbs.length;
			setSysexMessageVerb(sysexMessageVerbs[currentSysexMessageVerb.current]);
		}, 1000);
		return () => {
			if (sysexMessageInterval.current != undefined){
				clearInterval(sysexMessageInterval.current);
				sysexMessageInterval.current = undefined;
			}
		};
	}, []);



	return (
		<>
		<div className='homeContainer'>
			<img src={transparentKeyboard} className='synthImg' />
			<div className='imageBackground'>
				<div className='homeBox'>
					<div className='logoContainer'>
						<h1>all of your patches in one place</h1>
						<div className='description'>
							<p className='subHeaderLabel'>patchup is the fastest and easiest way to manage your synth's patches for free</p>
						</div>
					</div>
					<div className='separator' />
					<div className='headerCallToActionContainer'>
						<br/>
						<div class='downloadButtonContainer'>
							<a href='https://www.microsoft.com/store/apps/9NGFHJ4DGT72' target='_blank' className='microsoftStoreImg'>
								<img src={MicrosoftStore} width={40} height={40} style={{'margin-right': '10px'}}></img>
								<span>download</span>
							</a>
							<div className='small subtext' style={{'align-self': 'center'}}>on the Microsoft store</div>
						</div>
					</div>
				</div>
			</div>
			<div className='featuresContainer'>
				<div className='featuresDescriptionsContainer'>
					<div className='featureContainer'>
						<h3>Unlimited Patch Storage</h3>
						<div>
						Connect any synth to start saving and loading an unlimited number of patches
						</div>
					</div>
					<div className='featureContainer'>
						<h3>Easy Patch Management</h3>
						<div>Organize your patches in searchable folders and write notes for any patch</div>
					</div>
					<div className='featureContainer'>
						<h3>Simple Synth Communication</h3>
						<div>Send and receive SysEx messages to any synth in decimal, hex, or binary format</div>
					</div>
				</div>
			</div>
			<div className='slideshowContainer'>
				<h2 style={{'margin': '40px 0px'}}>What does it look like?</h2>
				<div className="slide-container">
					<div className='slide-image-container'>
						<span className='slideTitle'>{slideImages[currentSlide].title}</span>
						<span className='slideCaption'>{slideImages[currentSlide].caption}</span>
						<div className='imgContainer'>
							<a className='slideArrow' style={{'left': '0'}} onClick={() => setCurrentSlide((((currentSlide - 1) % slideImages.length) + slideImages.length ) % slideImages.length)} >
								<img src={ArrowLeft} width={60} height={60} />
							</a>
							<img className='slideImg' src={slideImages[currentSlide].url} alt={slideImages[currentSlide].altText} />
							<a className='slideArrow' style={{'right': '0'}} onClick={() => setCurrentSlide((currentSlide + 1) % slideImages.length)} >
								<img src={ArrowRight} width={60} height={60} />
							</a>
						</div>
					</div>
				</div>
				<div className="mobile-slide-container">
					<div className='slide-image-container'>
						{
							slideImages.map(slide => {
								return (
								<div style={{'margin': '40px 0px'}}>
									<span className='slideTitle'>{slide.title}</span>
									<span className='slideCaption'>{slide.caption}</span>
									<img className='slideImg' src={slide.url} alt={slide.altText} />
								</div>
								)
							})
						}
						
					</div>
				</div>
			</div>

			<div className='featuresContainer'>
				<div className='featuresDescriptionsContainer'>
					<div className='center'>
						<h3>100% Free</h3>
						<div>
						Patchup is a passion project, and all its development has been for fun – enjoy!
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);
}

export default Home;
