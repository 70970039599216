import './AppHome.css';
import MidiDeviceList from '../MidiDeviceList/MidiDeviceList';

function AppHome() {
  return (
    <div className="AppHome">
      <MidiDeviceList />
    </div>
  );
}

export default AppHome;
